import instance from "@/axios"

/** 拍品列表 */
export const getEditedNewAuctionData = async (productName) => {
    return instance({
        url: '/dq_admin/product/auctionGoodsList',
        method: 'GET',
        params: {
            pageSize: 2,
            pageNum: 1,
            productName: productName
        }
    })
}
/** 获取心理价加价幅度 */
export const getAuctionHeartPriceList = async () => {
    return instance({
        url: '/dq_admin/product/listIncrPriceList',
        method: 'GET',
    })
}

/** 编辑瑕疵 */
export const postAuctionRemark = async (params) => {
    return instance({
        url: '/dq_admin/product/editNormalProperty',
        method: 'POST',
        data: params
    })
}

/** 图片识别 */
export const getTempImgInfoApi = async (params) => {
    return instance({
        url: '/dq_admin/product/recoCategoryCoin',
        method: 'GET',
        params: params
    })
}